<template>
	<div class="rule">
		<div>
			<p class="title">巡诊医护人员 <span class="tip">(可多选)</span><span class="required">*</span></p>
			<el-checkbox-group :disabled="isShowDetail" :class="[isShowCheck ? 'show-check-border' : '']" v-model="userList">
				<el-checkbox :label="item.id" v-for="item in workerList" :key="item.id">{{ item.name }}</el-checkbox>
			</el-checkbox-group>
			<p class="title title3">巡检时间 <span class="tip">(可多选)</span><span class="required">*</span></p>
			<div class="columns" v-for="item in weekList" :key="item.day">
				<el-checkbox :disabled="isShowDetail" v-model="item.checked" @input="inputChecked(item)"></el-checkbox>
				<el-input v-model="item.day" disabled placeholder="请输入"></el-input>
				<el-select
					:disabled="isShowDetail"
					:class="[item.showBorder ? 'show-select-border' : '']"
					v-model="item.period"
					@change="changePeriod($event, item)"
					placeholder="请选择时间段"
				>
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
				<el-select v-model="item.time" disabled placeholder="时间">
					<el-option v-for="item in timeOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</div>
			<span style="color: #f56c6c; font-size: 13px" v-if="isShowAllTime">至少勾选一个时间</span>
			<div class="btn">
				<el-button type="primary" v-if="!isShowDetail" @click="onSave">保存</el-button>
				<el-button @click="back">取消</el-button>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false">
			<div slot="title" class="dialog-title">
				<i class="el-icon-warning"></i>
				<span>确定离开此页面吗？</span>
			</div>
			<div class="dialog-content">你所做的更改尚未保存，离开后将丢失当前编辑的内容</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="back">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			confirmLeave: false, //是否确定离开
			currentToPath: '', //想要前往的路由
			dialogVisible: false,
			isClickSave: false, //是否点击保存验证
			isShowFrequency: false,
			isShowAllTime: false,
			userList: [],
			workerList: [],
			areaList: [],
			frequency: '',
			stressCode: '',
			villageCode: '',
			isShowDetail: false,
			queryObj: {},
			weekList: [
				{ day: '周一', value: 'monday' },
				{ day: '周二', value: 'tuesday' },
				{ day: '周三', value: 'wednesday' },
				{ day: '周四', value: 'thursday' },
				{ day: '周五', value: 'friday' },
				{ day: '周六', value: 'saturday' },
				{ day: '周日', value: 'sunday' },
			],
			options: [
				{ value: 'AM', label: '上午' },
				{ value: 'PM', label: '下午' },
				{ value: 'AP', label: '全天' },
			],
			timeOption: [
				{ value: 'AM', label: '09:00~12:00' },
				{ value: 'PM', label: '13:00~18:00' },
				{ value: 'AP', label: '09:00~18:00' },
			],
		};
	},
	components: {},
	computed: {
		isShowCheck() {
			return this.isClickSave && this.userList.length == 0;
		},
	},
	mounted() {
		this.workerList = JSON.parse(sessionStorage.getItem('currentGroup'));
	},
	created() {
		this.queryObj = this.$route.query;
		this.userList = this.queryObj.medicalWorkerIds || [];
		this.stressCode = this.queryObj.stressCode;
		this.villageCode = this.queryObj.villageCode;
		this.areaList = this.queryObj.areaList;
		this.isShowDetail = this.queryObj.isShowDetail;
		this.weekList.forEach((item) => {
			if (this.queryObj[item.value + 'Enabled']) {
				this.$set(item, 'time', this.queryObj[item.value]);
				this.$set(item, 'period', this.queryObj[item.value]);
				this.$set(item, 'checked', true);
			}
		});
	},
	methods: {
		inputChecked(item) {
			this.isShowAllTime = false;
			this.$set(item, 'showBorder', false);
		},
		// 改变时间段
		changePeriod(e, item) {
			this.$set(item, 'time', e);
			this.$set(item, 'showBorder', false);
		},
		//保存
		onSave() {
			this.isClickSave = true;
			// this.onBlur(); //验证巡检
			let num = 0;
			let isShowSelect = false;
			let obj = {};
			this.weekList.forEach((item) => {
				item.checked ? num++ : '';
				if (item.checked && !item.period) {
					this.$set(item, 'showBorder', true);
					isShowSelect = true;
				}
			});
			this.isShowAllTime = num == 0 ? true : false;
			if (this.isShowAllTime || isShowSelect || this.isShowFrequency || this.isShowCheck) return;
			this.weekList.forEach((item) => {
				if (item.checked) {
					obj[item.value] = item.period;
					obj[item.value + 'Enabled'] = true;
				} else {
					obj[item.value + 'Enabled'] = false;
				}
			});
			obj.medicalWorkerIds = this.userList;
			obj.stressCode = this.stressCode;
			obj.villageCode = this.villageCode;
			obj.villageCode = this.villageCode;
			obj.noRule = false;
			obj.areaList = this.areaList;
			sessionStorage.setItem('rulesItem', JSON.stringify(obj));
			this.confirmLeave = true;
			this.$router.push({ path: '/patrolWorkArrangement/visitPlan' });
		},
		// 失焦
		onBlur() {
			if (!this.frequency || this.frequency < 2) {
				this.isShowFrequency = true;
			} else {
				this.isShowFrequency = false;
			}
		},
		// 返回
		back() {
			this.confirmLeave = true;
			sessionStorage.setItem('rulesItem', JSON.stringify(this.queryObj));
			if (this.isShowDetail == true) {
				this.$router.push({
					path: '/patrolWorkArrangement/visitPlan',
					query: {
						isShowDetail: this.isShowDetail,
					},
				});
			} else {
				this.$router.push({
					path: '/patrolWorkArrangement/visitPlan',
				});
			}
		},
	},
	beforeRouteLeave(to, from, next) {
		this.currentToPath = to.path;
		if (this.confirmLeave) {
			next();
		} else {
			this.dialogVisible = true;
		}
	},
};
</script>
<style scoped lang='scss'>
.dialog-title {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: bold;
	.el-icon-warning {
		font-size: 22px;
		color: #ffac5f;
		margin-right: 12px;
	}
}
.dialog-content {
	color: #666;
	margin-top: -10px;
}

.show-frequency-border {
	/deep/ .el-input__inner {
		border-color: #f56c6c !important;
	}
	position: relative;
}
.show-frequency-border::after {
	position: absolute;
	content: '最少频次为2';
	color: #f56c6c;
	left: 0;
	top: 34px;
	font-size: 12px;
}
.show-check-border {
	position: relative;
	/deep/ .el-input__inner {
		border-color: #f56c6c !important;
	}
}
.show-check-border::after {
	position: absolute;
	content: '至少选择一名人员';
	color: #f56c6c;
	left: 0;
	bottom: 0px;
	font-size: 12px;
}
.show-select-border {
	/deep/ .el-input__inner {
		border-color: #f56c6c !important;
	}
}
.rule {
	box-sizing: border-box;
	width: calc(100% -32px);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin: 16px;
	background: #fff;
	border-radius: 6px;
	padding: 24px 20px;
	.title3 {
		margin-top: 28px;
	}
	.title {
		color: #303133;
		margin-bottom: 8px;
		.tip {
			color: #999;
		}
		.required {
			color: #f56c6c;
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
	/deep/ .el-checkbox-group {
		width: 265px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 8px;
		.el-checkbox {
			margin-bottom: 20px;
		}
	}
	.columns {
		margin-top: 8px;
		margin-bottom: 4px;
		display: grid;
		gap: 8px;
		grid-template-columns: 11px 160px 160px 160px;
		/deep/ .el-checkbox {
			line-height: 30px;
		}
	}
	.btn {
		display: flex;
		justify-content: center;
		margin-top: 70px;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset;
		padding-top: 24px;
	}
}
</style>